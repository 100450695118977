<template>
    <article class="px-0 pt-4 bg-primary mb-n4" id="personaSummary">
        <!-- Demographics summary -->
        <summary-section>
            <template #icon>
                <font-awesome-icon :icon="['duotone', FeatureIcons.DEMOGRAPHICS]" size="lg"/>
            </template>
            <template #title>
                <router-link :to="summaryLink('demographics', 'age-income-home')">Demographics</router-link>
            </template>
            <template #content>
                <div class="row">
                    <div v-for="(chart, index) of chartData.demographics.slice(0, 4)"
                        :key="index"
                        class="col-sm-6 col-lg-3"
                        data-chart-container
                        v-memo="chartData"
                    >
                        <highcharts
                            :options="chart"
                        />
                    </div>
                </div>

                <!-- Hidden demographics collapse section -->
                <div class="row g-0 collapse" id="additionalDemographicsCollapse">
                    <div v-for="(chart, index) of chartData.demographics.slice(4)"
                        :key="index"
                        class="col-sm-6 col-lg-3"
                        data-chart-container
                        v-memo="chartData"
                    >
                        <highcharts
                            :options="chart"
                        />
                    </div>
                </div>

                <div class="w-100 text-center my-n3">
                    <button class="btn btn-link margin-auto"
                            data-bs-toggle="collapse"
                            data-bs-target="#additionalDemographicsCollapse"
                            @click="demographicsExpanded = !demographicsExpanded"
                    >
                        <font-awesome-icon
                            v-show="demographicsExpanded"
                            :icon="['solid', 'angle-up']"
                        />
                        <font-awesome-icon
                            v-show="!demographicsExpanded"
                            :icon="['solid', 'angle-down']"
                        />
                    </button>
                </div>
            </template>
        </summary-section>

        <div class="row col-padding-half" data-masonry='{"percentPosition": true}'>
            <!-- Unique Facts -->
            <div class="col-lg-6">
                <summary-section>
                    <template #icon>
                        <img class="logo-icon"
                            :src="iconPath['personaBuilder']"
                            alt=""
                            tabindex="-1"
                        >
                    </template>
                    <template #title>
                        Unique Facts
                    </template>
                    <template #content>
                        <wedge-chart-section :data="{items: chartData.uniqueFacts}"/>
                    </template>
                </summary-section>
            </div>

            <!-- Consumer Spend -->
            <div v-if="chartData.consumerSpendActivity.length > 0" class="col-lg-6">
                <summary-section>
                    <template #icon>
                        <font-awesome-icon :icon="['duotone', FeatureIcons.CONSUMER_SPENDING]" size="lg"/>
                    </template>
                    <template #title>
                        <router-link :to="summaryLink('consumer-spending', 'summary')">Consumer Spend</router-link>
                    </template>
                    <template #content>
                        <highcharts
                            v-for="(consumerSpendChart, index) of chartData.consumerSpendActivity"
                            :key="`consumer_spend_${index}`"
                            :options="consumerSpendChart"
                        />
                    </template>
                </summary-section>
            </div>

            <!-- High-level RFM -->
            <div v-if="chartData.highLevelRfm" class="col-lg-6">
                <summary-section>
                    <template #icon>
                        <font-awesome-icon :icon="['duotone', FeatureIcons.HIGH_LEVEL_RFM]" size="lg"/>
                    </template>
                    <template #title>
                        <router-link :to="summaryLink('rfm')">RFM</router-link>
                    </template>
                    <template #content>
                        <div data-chart-container>
                            <highcharts :options="chartData.highLevelRfm"/>
                        </div>
                    </template>
                </summary-section>
            </div>

            <!-- Conglomerate RFM / IND33 / Market Affinity-->
            <div v-if="chartData.conglomerateRfm" class="col-lg-6">
                <summary-section>
                    <template #icon>
                        <font-awesome-icon :icon="['duotone', FeatureIcons.CONGLOMERATE_RFM]" size="lg"/>
                    </template>
                    <template #title>
                        <router-link :to="summaryLink('market-affinity')">Market Affinity, by Spend</router-link>
                    </template>
                    <template #content>
                        <div data-chart-container>
                            <highcharts :options="chartData.conglomerateRfm"/>
                        </div>
                    </template>
                </summary-section>
            </div>

            <!-- Past Purchases -->
            <div class="col-lg-6">
                <summary-section>
                    <template #icon>
                        <font-awesome-icon :icon="['duotone', FeatureIcons.PAST_PURCHASES]" size="lg"/>
                    </template>
                    <template #title>
                        <router-link :to="summaryLink('past-purchases')">
                            Past Purchase Activity
                        </router-link>
                    </template>
                    <template #content>
                        <highcharts
                            v-for="(pastPurchaseChart, index) of chartData.pastPurchaseActivity"
                            :key="`past_purchase_${index}`"
                            :options="pastPurchaseChart"
                        />
                    </template>
                </summary-section>
            </div>

            <!-- Weekly social activity -->
            <div class="col-lg-6">
                <summary-section>
                    <template #icon>
                        <font-awesome-icon :icon="['duotone', 'users']"/>
                    </template>
                    <template #title>
                        <router-link :to="summaryLink('social-activity')">Weekly Activity</router-link>
                    </template>
                    <template #content>
                        <highcharts
                            :options="chartData.socialWeeklyActivity"
                        />
                    </template>
                </summary-section>
            </div>

            <!-- Political social engagement -->
            <div class="col-lg-6">
                <summary-section>
                    <template #icon>
                        <font-awesome-icon :icon="['duotone', FeatureIcons.POLITICAL]" size="lg"/>
                    </template>
                    <template #title>
                        <router-link :to="summaryLink('interests', 'summary')">Political Social Engagement</router-link>
                    </template>
                    <template #content>
                        <div class="row">
                            <template v-if="chartData.politicalSocialSummary.hasOwnProperty('twitter')">
                                <summary-widget
                                    v-show="itemContext['politicalSocialEngagement'] === 'twitter'"
                                    class="col-6"
                                    :summaryItems="[chartData.politicalSocialSummary.twitter[1]]"
                                    summaryType="twitter"
                                    tooltipPattern="[[ percent ]] of the group has engaged"
                                />
                                <summary-widget
                                    v-show="itemContext['politicalSocialEngagement'] === 'twitter'"
                                    class="col-6"
                                    :summaryItems="[chartData.politicalSocialSummary.twitter[0]]"
                                    summaryType="twitter"
                                    tooltipPattern="[[ percent ]] of the group has engaged"
                                />
                            </template>
                        </div>
                    </template>
                </summary-section>
            </div>

            <!-- Political party affiliation -->
            <div class="col-lg-6">
                <summary-section>
                    <template #icon>
                        <font-awesome-icon :icon="['duotone', FeatureIcons.POLITICAL]" size="lg"/>
                    </template>
                    <template #title>
                        <router-link
                            :to="summaryLink('demographics', 'politics-job-residence')">Political Party Affiliation
                        </router-link>
                    </template>
                    <template #content>
                        <highcharts
                            :options="chartData.politicalPartyAffiliation"
                        />
                    </template>
                </summary-section>
            </div>

            <!-- Brand & Interest Summary -->
            <div class="col-lg-6">
                <div class="row col-padding-half">
                    <div class="col-6 mb-0">
                        <summary-section>
                            <template #icon>
                                <span class="text-gray-75">
                                    <font-awesome-icon fixed-width :icon="['duotone', FeatureIcons.SOCIAL_AFFINITY]"/>
                                </span>
                            </template>
                            <template #title>
                                <router-link :to="summaryLink('brands', 'summary')">Brand Summary</router-link>
                            </template>
                            <template #content>
                                <summary-widget
                                    v-if="chartData.brandSummary.hasOwnProperty('twitter')"
                                    v-show="itemContext['brand'] === 'twitter'"
                                    :summaryItems="chartData.brandSummary.twitter"
                                    summaryType="twitter"
                                    tooltipPattern="[[ percent ]] of the group is interested"
                                />
                            </template>
                        </summary-section>
                    </div>

                    <div class="col-6 mb-0">
                        <summary-section>
                            <template #icon>
                                <span class="text-gray-75">
                                    <font-awesome-icon fixed-width :icon="['duotone', FeatureIcons.SOCIAL_AFFINITY]"/>
                                </span>
                            </template>
                            <template #title>
                                <router-link :to="summaryLink('interests', 'summary')">Interest Summary</router-link>
                            </template>
                            <template #content>
                                <summary-widget
                                    v-if="chartData.interestSummary.hasOwnProperty('twitter')"
                                    v-show="itemContext['interest'] === 'twitter'"
                                    :summaryItems="chartData.interestSummary.twitter"
                                    summaryType="twitter"
                                    tooltipPattern="[[ percent ]] of the group is interested"
                                />
                            </template>
                        </summary-section>
                    </div>
                </div>
            </div>
        </div>

        <!-- Topics -->
        <div class="row col-padding-half">
            <div v-for="topic of chartData.socialTopics"
                 class="col-lg-3"
                 :key="topic.key"
            >
                <summary-section>
                    <template #icon>
                        <span class="text-gray-75">
                            <font-awesome-icon :icon="['duotone', FeatureIcons.SOCIAL_AFFINITY]" size="lg"/>
                        </span>
                    </template>
                    <template #title>
                        <router-link
                            :to="summaryLink(
                                topic.tab,
                                topic.tab === 'custom-topics' ? topic.section : 'categories',
                                topic.tab !== 'custom-topics' ? {context: topic.section} : null
                            )"
                        >
                            {{ topic.category || topic.name }}
                        </router-link>
                    </template>
                    <template #content>
                        <persona-topic-widget
                            :topic="topic"
                            :context="itemContext[`topic-${topic.key}`]"
                        />
                    </template>
                </summary-section>
            </div>
        </div>

        <!-- Geography -->
        <summary-section>
            <template #icon>
                <font-awesome-icon :icon="['duotone', FeatureIcons.GEOGRAPHY]" size="lg"/>
            </template>
            <template #title>
                <router-link :to="summaryLink('geography')">Geography</router-link>
            </template>
            <template #content>
                <div class="row">
                    <div class="col">
                        <highcharts
                            constructor-type="mapChart"
                            :options="chartData.geography.chart"
                            style="overflow: visible !important;"
                        />
                    </div>

                    <!-- Detail sidebar -->
                    <div class="col-3">
                        <div v-for="detailSection of chartData.geography.detail"
                            class="card bg-blue-10 border-0 p-3 mb-3">
                            <u class="h5 text-primary mb-3 mx-auto">{{ detailSection.name }}</u>
                            <div style="max-height: 17em; overflow: auto">
                                <table class="text-primary w-100">
                                    <tr v-for="item of detailSection.items.index"
                                        :key="item.label">
                                        <td class="align-top">{{ item.index }}. {{ item.label }}</td>
                                        <td class="align-top text-end text-nowrap">{{ item.value }}</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </summary-section>

        <div>&nbsp;</div>
    </article>
</template>

<script lang="ts">
    import {Vue, Component, Prop, toNative} from 'vue-facing-decorator';
    import Highcharts from 'highcharts';
    import {Chart} from 'highcharts-vue';
    import mapInit from 'highcharts/modules/map';
    import solidGaugeInit from 'highcharts/modules/solid-gauge';
    import clone from 'lodash-es/clone';
    import Masonry from 'masonry-layout';
    import * as Utils from 'Utilities/utils';
    import {iconPath} from 'Utilities/reports';
    import FontAwesomeIcon from "Components/common/font-awesome-icon";
    import SummarySection from "Components/common/report/summary-section";
    import SummaryWidget from "Components/common/report/summary-widget";
    import PersonaTopicWidget from "./persona-topic-widget";
    import {FeatureIcons, useAppStore} from 'Stores/common/app';
    import {PersonaLoaders} from 'Stores/persona';
    import WedgeChartSection from 'Components/common/report/wedge-chart-section.vue';

    // highchartsMore(Highcharts);
    mapInit(Highcharts);
    solidGaugeInit(Highcharts);

    @Component<PersonaSummary>({
        components: {
            highcharts: Chart,
            FontAwesomeIcon,
            PersonaTopicWidget,
            SummaryWidget,
            SummarySection,
            WedgeChartSection,
        },
        setup() {
            return {
                appStore: useAppStore()
            };
        },
    })
    class PersonaSummary extends Vue {
        @Prop({
            type: Object,
            default: () => {
                return {};
            }
        })
        chartData: any;

        @Prop({
            type: Array,
            default: () => {
                return [];
            }
        })
        detail;

        @Prop({
            type: Object,
            default: () => {
            }
        })
        params;

        iconPath = iconPath;
        personaReportLoader: string = `${PersonaLoaders.PREP}-page`;
        defaultSocialContext = 'twitter';
        demographicsExpanded: boolean = false;
        itemContext = {
            brand: this.defaultSocialContext,
            interest: this.defaultSocialContext,
            politicalSocialEngagement: this.defaultSocialContext,
        };

        /* Can be removed when moving to Composition API */
        FeatureIcons = FeatureIcons;
        Utils = Utils;

        get personaReportLoading(): boolean {
            return !this.appStore.getLoaderActive(this.personaReportLoader);
        }

        summaryLink(tab: string, section?: string, query?: any) {
            let link = Object.assign(clone(this.$route), {
                params: {
                    tab,
                    section
                }
            });

            if (!section) {
                switch (tab) {
                    case 'brands':
                    case 'consumer-spending':
                    case 'interests':
                        link.params.section = 'summary';
                        break;

                    case 'custom-topics':
                        link.params.section = 'social-characteristics';
                        break;

                    case 'demographics':
                        link.params.section = 'age-income-home';
                        break;

                    // case 'geographic-areas':
                    //     link.params.section = 'media-markets';
                    //     break;

                    case 'market-affinity':
                        link.params.section = 'overview';
                        break;

                    case 'past-purchases':
                        link.params.section = 'clothing-children';
                        break;

                    case 'rfm':
                        link.params.section = 'revenue';
                        break;
                }
            }

            if (query) {
                link.query = query;
            }

            return link;
        }

        created() {
            // Initialize item contexts
            for (const socialTopic of this.chartData.socialTopics) {
                this.itemContext[`topic-${socialTopic.key}`] = this.defaultSocialContext;
            }
        }

        mounted() {
            const grid = document.querySelector('[data-masonry]')
            const msnry = new Masonry(grid)
            // msnry.once('layoutComplete', () => {
            //     grid.classList.add('load')
            // })

            msnry.layout();
        }
    }
    export default toNative(PersonaSummary);
</script>

<!--<style scoped lang="scss" src="./persona-summary.scss"/>-->
