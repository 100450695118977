import {nextTick, toRaw} from 'vue';
import {RouteRecordRaw, createRouter, createWebHistory} from 'vue-router';
import AudienceReport from 'Components/audience/report/report.vue';
import ComparisonReport from 'Components/comparison/report/report.vue';
import PersonaReport from 'Components/persona/report/report.vue';
import {uuidPattern} from 'Server/api';
import {useAccountStore} from 'Stores/account';
import {Loaders, useAppStore} from 'Stores/common/app';
import {useNavigationStore} from 'Stores/common/navigation/navigation';
import {useUserStore} from 'Stores/user';
import {Actions} from 'Utilities/immutables';
import {isValidLastPath} from 'Utilities/utils';

const Account = () => import(/* webpackChunkName: "Account" */ 'Components/account/account.vue');
const Activity = () => import(/* webpackChunkName: "Activity" */ 'Components/activity.vue');
const AppError = () => import(/* webpackChunkName: "AppError" */ 'Components/app/error.vue');
const AudienceBuild = () => import(/* webpackChunkName: "Audience" */ 'Components/audience/build/audience-build.vue');
const AudienceDistributionConfirm = () => import(/* webpackChunkName: "AudienceDistributionConfirm" */ 'Components/audience/audience-distribution-confirm.vue');
const AudienceSend = () => import(/* webpackChunkName: "AudienceSend" */ 'Components/audience/send/send.vue');
const Comparison = () => import(/* webpackChunkName: "Comparison" */ 'Components/comparison/comparison.vue');
const ForgotPassword = () => import(/* webpackChunkName: "ForgotPassword" */ 'Components/login/forgot-password.vue');
const HelpKnowledgeBase = () => import(/* webpackChunkName: "HelpKnowledgeBase" */ 'Components/help/help.vue');
const LandingPage = () => import(/* webpackChunkName: "LandingPage" */ 'Components/landing-page.vue');
const ListLibrary = () => import(/* webpackChunkName: "ListLibrary" */ 'Components/account/list-library/list-library.vue');
const Login = () => import(/* webpackChunkName: "Login" */ 'Components/login/login.vue');
const PersonaBuild = () => import(/* webpackChunkName: "Persona" */ 'Components/persona/build/persona-build.vue');
const ResetPassword = () => import(/* webpackChunkName: "ResetPassword" */ 'Components/login/reset-password.vue');
const TestComponent = () => import(/* webpackChunkName: "TestComponent" */ 'Components/test-component.vue');
const TopicLibrary = () => import(/* webpackChunkName: "TopicLibrary" */ 'Components/account/topic-library/topic-library.vue');
const UserFeedback = () => import(/* webpackChunkName: "UserFeedback" */ 'Components/user/user-feedback.vue');
const UserProfile = () => import(/* webpackChunkName: "UserProfile" */ 'Components/user/user-profile.vue');

const accountRoot = `/:accountId(${uuidPattern})`;
const shareRoot = `/share/:token(${uuidPattern})`;
const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'landing',
        component: LandingPage,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: `${accountRoot}/`,
        name: 'activity',
        component: Activity,
        meta: {
            requiresAuth: true,
            forceRouteRefresh: true
        }
    },
    {
        path: `${accountRoot}/account/:tab?`,
        name: 'account',
        component: Account,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: `${accountRoot}/audience/build/:tab?/:section?/:id(${uuidPattern})?`,
        name: 'audienceBuild',
        component: AudienceBuild,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: `${accountRoot}${shareRoot}/audience/:id(${uuidPattern})/report/pdf`,
        name: 'audienceReportPDF',
        component: AudienceReport,
        meta: {
            requiresAuth: false,
            requiresShareKey: true
        }
    },
    {
        path: `${accountRoot}${shareRoot}/audience/:id(${uuidPattern})/report/:tab?/:section?`,
        name: 'audienceReportShare',
        component: AudienceReport,
        meta: {
            requiresAuth: false,
            requiresShareKey: true
        }
    },
    {
        path: `${accountRoot}/audience/:id(${uuidPattern})/report/:tab?/:section?`,
        name: 'audienceReport',
        component: AudienceReport,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: `${accountRoot}/audience/distribution/:confirmationId(${uuidPattern})/:action(confirm|deny)`,
        name: 'audienceDistributionConfirm',
        component: AudienceDistributionConfirm,
        meta: {
            requiresAuth: false,
            requiresShareKey: false,
            requiresToken: true
        }
    },
    {
        path: `${accountRoot}/audience/send/:id(${uuidPattern})?`,
        name: 'audienceSend',
        component: AudienceSend,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: `${accountRoot}/comparison/build/:tab?`,
        name: 'comparisonBuild',
        component: Comparison,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: `${accountRoot}${shareRoot}/comparison/:id(${uuidPattern})/report/pdf/:context?`,
        name: 'comparisonReportPDF',
        component: ComparisonReport,
        meta: {
            requiresAuth: false,
            requiresShareKey: true
        }
    },
    {
        path: `${accountRoot}${shareRoot}/comparison/:id(${uuidPattern})/report/:tab?/:section?`,
        name: 'comparisonReportShare',
        component: ComparisonReport,
        meta: {
            requiresAuth: false,
            requiresShareKey: true
        }
    },
    {
        path: `${accountRoot}/comparison/:id(${uuidPattern})/report/:tab?/:section?`,
        name: 'comparisonReport',
        component: ComparisonReport,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: `${accountRoot}/persona/build/:tab?/:section?/:id(${uuidPattern})?`,
        name: 'personaBuild',
        component: PersonaBuild,
        meta: {
            requiresAuth: true,
            steps: 0
        }
    },
    {
        path: `${accountRoot}/persona/compare`,
        name: 'personaCompare',
        component: ComparisonReport,
        meta: {
            requiresAuth: true
        }
    },
    {
        // e.g. /2ee38f4c-f6c1-4eb9-8a03-a6497f55f920/share/00000000-1111-2222-3333-444444444444/persona/f0aec7dd-3e55-11eb-9341-0e1b1041fbc8/report/pdf
        path: `${accountRoot}${shareRoot}/persona/:id(${uuidPattern})/report/pdf`,
        name: 'personaReportPDF',
        component: PersonaReport,
        meta: {
            requiresAuth: false,
            requiresShareKey: true
        }
    },
    {
        // e.g. /2ee38f4c-f6c1-4eb9-8a03-a6497f55f920/share/00000000-1111-2222-3333-444444444444/persona/f0aec7dd-3e55-11eb-9341-0e1b1041fbc8/report/consumer-spending/summary
        path: `${accountRoot}${shareRoot}/persona/:id(${uuidPattern})/report/:tab?/:section?`,
        name: 'personaReportShare',
        component: PersonaReport,
        meta: {
            requiresAuth: false,
            requiresShareKey: true
        }
    },
    {
        path: `${accountRoot}/persona/:id(${uuidPattern})/report/:tab?/:section?`,
        name: 'personaReport',
        component: PersonaReport,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: `${accountRoot}/list-library`,
        name: 'listLibrary',
        component: ListLibrary,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: `${accountRoot}/users/:id/reset`,
        name: 'resetPassword',
        component: ResetPassword,
        meta: {
            requiresAuth: false,
            requiresToken: true
        }
    },
    {
        path: `${accountRoot}/user/feedback`,
        name: 'userFeedback',
        component: UserFeedback,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: `${accountRoot}/user`,
        name: 'user',
        component: UserProfile,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: `${accountRoot}/users/:id/register`,
        name: 'userRegister',
        component: UserProfile,
        meta: {
            requiresAuth: false,
            requiresToken: true
        }
    },
    {
        path: `${accountRoot}/topics`,
        name: 'topicLibrary',
        component: TopicLibrary,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/help/:tab?',
        name: 'help',
        component: HelpKnowledgeBase,
        meta: {
            hasNavigation: false,
            requiresAuth: true
        }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        props: true,
        meta: {
            hasNavigation: false,
            requiresAuth: false
        }
    },
    {
        path: '/login/forgot-password',
        name: 'forgotPassword',
        component: ForgotPassword,
        meta: {
            hasNavigation: false,
            requiresAuth: false
        }
    },
    {
        path: '/test-component',
        name: 'testComponent',
        component: TestComponent,
        meta: {
            requiresAuth: false,
            requiresShareKey: false
        }
    },
    {
        // Fall back to a 404 error page
        path: '/:catchAll(.*)',
        name: 'error404',
        component: AppError,
        meta: {
            requiresAuth: false
        }
    }
];

let router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior: (to, from, savedPosition): any => {
        if (to.hash) {
            nextTick(() => {
                return {
                    el: to.hash,
                    behavior: 'smooth',
                }
            });
        } else {
            // Return to top of page on any route change
            return { x: 0, y: 0 }
        }
    }
});

router.beforeEach(async (to: any, from: any, next) => {
    if ((to.name && from.name) && (to.name !== from.name)) {
        const loader: string = to.name === 'login' ? Actions.LOGOUT_PENDING : Actions.NAVIGATING;
        useAppStore().setLoader(loader);
    }

    if (!from.matched.length && to.path === '/' && to.hash.match(/^#\//)) {
        // Handle old links using the hash method
        // console.log(`[APP] handle OLD url: ${to.hash}`, to);
        next(to.hash.replace(/^#\//, ''));
        return;
    }

    // Update navigation component
    const navigationStore = useNavigationStore();
    navigationStore.setNavigationDisplay(to.name);
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const requiresShareKey = to.matched.some(record => record.meta.requiresShareKey);
    const accountId: string | null = to.params?.accountId ? to.params?.accountId : null;
    let shared: boolean = false;

    to.params.refresh = await useAppStore().appRefreshRequired();
    console.debug('App refresh required:', to.params.refresh);
    to.params['shared'] = shared.toString();

    if (requiresAuth || requiresShareKey) {
        const accountStore = useAccountStore();
        // This route requires a user or token access check...
        let authenticated: boolean;

        if (requiresAuth) {
            const userStore = useUserStore();
            // Check for active user in state...
            authenticated = await userStore.setUserAuthentication();

            if (!authenticated) {
                // Check for a persisted user; if found, this will update the state
                console.debug('💾 Not authenticated - refresh data...');
                accountStore.clearAccountMetadata();
                await userStore.setPersistedUser();
                authenticated = await userStore.setUserAuthentication();
            }
        } else {
            // Check for a shared key
            authenticated = await useAppStore().isSharedKey( to );
            shared = true;
        }

        if (accountId && (accountId !== accountStore.account.id)) {
            // Use the account ID parameter to set the active account
            authenticated = await accountStore.setAccount({accountId, isInitial: true, shared});
        } else if (shared) {
            await accountStore.setAccount({accountId, isInitial: true, shared});
        }

        //console.debug(`AUTH CHECK COMPLETE (${requiresAuth ? 'USER' : 'SHARED'}: ${authenticated ? 'PASS' : 'FAIL'})`, accountId, store.getters.getUser);

        if (authenticated) {
            // Access confirmed, ensure account meta data is set
            await accountStore.setAccountMetadata(accountStore.getAccount);

            // Set shared key route param
            to.params.shared = shared.toString();
            // Allow it through
            next();
        } else {
            // Access was not verified...
            const lastPath = isValidLastPath(to.fullPath) ? to.fullPath : '/';
            window.sessionStorage.setItem('previousRoute', lastPath);
            if (from.name !== 'login') {
                next('/login');
            }
        }
    } else {
        // This route is open to anyone, allow it through
        next();
    }
});
router.afterEach(async (to, from) => {
    const navigationStore = useNavigationStore();
    navigationStore.setNavigation(to.name);

    if (to.params.refresh) {
        console.debug('🛠 App upgraded - refresh required');
        window.sessionStorage.setItem('previousRoute', to.path);
        useAccountStore().clearAccountMetadata();
        await nextTick(() => {
            window.location.reload();
        });
    }

    if ( to.name !== from.name ) {
        await nextTick(() => {
            setTimeout( async () => {
                useAppStore().clearLoader(Actions.NAVIGATING);
            }, 3000 );
        });
    }
});

export default router;
